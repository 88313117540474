import React from 'react';
import './HeroSection.css'; 
import { ButtonShared } from '../../shared/button/ButtonShared';

export function HeroSection() {
  const handleClick = () => {
    const element = document.getElementById("section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="hero-section">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>Pesquisa, Inovação e Ciência de Dados</h1>
        <p>Um laboratório dedicado a oferecer as mais inovadoras soluções científicas
        para atender às suas necessidades.</p>
        <ButtonShared onClick={handleClick} text={'Saiba mais'}/>
      </div>
    </div>
  );
};


