import React, { useState } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import './Header.css';
import logo from '../../../assets/logos/logo-preto-verde.svg';
import { MenuOutlined } from '@ant-design/icons';

const { Header } = Layout;

export function HeaderMain() {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };
  
  const onClose = () => {
    setVisible(false);
  };

  const menuItems = [
    { key: 'hero', label: 'Início' },
    { key: 'section', label: 'Sobre nós' },
    { key: 'services', label: 'Serviços' },
    { key: 'testimonials', label: 'Equipe' },
    { key: 'footer', label: 'Contato' },
  ];
  
  return (
    <Header className="app-header">
      <img src={logo} alt="Logo Orion" className="logo" />
      <div className="header-background" />
      <div className="header-container">
      
      <Button
        className="mobile-menu-button"
        onClick={showDrawer}
        icon={<MenuOutlined color='#0F172A'/>} 
      >
      </Button>
    </div>

    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={['1']}
      className="menu desktop-menu"
      onClick={({ key }) => handleMenuClick(key)}
      items={menuItems}
    />
  
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={visible}
        className="mobile-menu"
      >
        <Menu
          theme="light"
          mode="vertical"
          onClick={({ key }) => {
            handleMenuClick(key);
            onClose(); 
          }}
          items={menuItems}
        >
        </Menu>
      </Drawer>
    </Header>
  );
  
}
