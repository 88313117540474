import React from 'react';
import './TestimonialSection.css'; 
import quoteStart from '../../../assets/icons/quote-start.svg';
import quoteEnd from '../../../assets/icons/quote-end.svg';

export function TestimonialSection () {
  return (
    <div className="testimonial-container">
      <div className="testimonial-content">
        <img src={quoteStart} alt="Start Quote" className="quote-start" />
        <div>
          <p className="testimonial-text">
          Orion - Laboratório de Pesquisa e Ciência dos Dados: Transformamos estudantes em profissionais de excelência, prontos para liderar o futuro. Com cada descoberta, criamos oportunidades e impulsionamos carreiras, inspirando mentes brilhantes a alcançar o seu potencial máximo. 
          Acreditamos no poder da inovação e, junto com nossos parceiros de negócios, construímos pontes que conectam talento e mercado, moldando um amanhã repleto de possibilidades.
          </p>
          <p className="testimonial-author">
          André Aquino, Coordenador Geral
          </p>
        </div>
        <img src={quoteEnd} alt="End Quote" className="quote-end" />
      </div>
      {/* <div className="testimonial-illustration">
        <img src={illustration} alt="Illustration" />
      </div> */}
    </div>
  );
};

