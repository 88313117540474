import React from 'react';
import { ButtonShared } from '../../shared/button/ButtonShared';
import './Footer.css'; 
import logo from '../../../assets/logos/logo-preto-verde.svg';

export function Footer() {
  const handleClick = () => {
    window.open("https://forms.gle/q6rdW85KZBkLnEBQ8");
  }
  return (
    <>
    <footer className="footer">
    <div className="content">
      <p>Seja uma estrela na constelação do <span className="highlight">Orion!</span></p>
      <ButtonShared onClick={handleClick} text='Preencher formulário'/>
    </div>
  </footer>
  <div className="footer-bottom">
        <div className="copyright">
          <img src={logo} alt="logo" style={{ width: '100px', height: '28px' }}></img>
           2024 - Todos os direitos reservados
        </div>
        <div className="social-media-icons">
          {/* <img src={message} alt="YouTube" /> */}
        </div>
      </div>
  </>
  );
}


