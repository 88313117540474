import React from 'react';
// import YouTube from 'react-youtube';
import './Player.css';

export function Player() {
//   const opts = {
//     height: '300',
//     width: '490',
//     playerVars: {
//       autoplay: 0,
//       controls: 1,
//       showinfo: 0,
//       modestbranding: 1,
//     },
//   };

  return (
    <div className="player-section">
      {/* <YouTube
        videoId="kLamK0WaxIo"
        opts={opts}
        className="youtube-player"
      /> */}
    </div>
  );
}
