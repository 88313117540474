import React from "react";
import { Button } from "antd";
import './ButtonShared.css';

export function ButtonShared(props) {
    return (
        <Button
            className="button-shared"
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    );
}