import './App.css';
import { Footer } from '../components/home/footer/Footer';
import { HeaderMain } from '../components/home/header/Header';
import { Services } from '../components/home/services/Services';
import { Section } from '../components/home/section/Section';
import { HeroSection } from '../components/home/hero-section/HeroSection';
import { TechSection } from '../components/home/tech-section/TechSection';
import { TestimonialSection } from '../components/home/testimonial-section/TestimonialSection';

function App() {
  return (
    <div className="App">
      <HeaderMain />
      <div id="hero"><HeroSection /></div>
      <div id="section"><Section /></div>
      <div id="services"><Services /></div>  
      <div id="testimonials"><TestimonialSection /></div>
      <div id="tech"><TechSection /></div>
      <div id="footer"><Footer /></div>
    </div>
  );
}

export default App;
