import React from 'react';
import { Row, Col, Typography } from 'antd';
import './Services.css';
import connect from '../../../assets/icons/connect.svg';
import laptop from '../../../assets/icons/laptop.svg';
import database from '../../../assets/icons/database-success.svg';
import message from '../../../assets/icons/message-search.svg';
import city from '../../../assets/icons/city-one.svg';
const { Title } = Typography;

const services = [ 
  {
    icon: <img src={database} alt="database" style={{ width: '48px', height: '48px' }} />,
    title: 'Coleta e Sensoriamento de Dados',
  },
  {
    icon: <img src={message} alt="data-analysis" style={{ width: '48px', height: '48px' }} />,
    title: 'Análise de Dados',
  },
  {
    icon: <img src={connect} alt="Connect" style={{ width: '48px', height: '48px' }} />,
    title: 'Organização e Integração de Dados',
  },
  {
    icon: <img src={laptop} alt="laptop" style={{ width: '48px', height: '48px' }} />,
    title: 'Inteligência Computacional',
  },
  {
    icon: <img src={city} alt="city" style={{ width: '48px', height: '48px' }} />,
    title: 'Cidades Inteligentes',
  },
];

export function Services() {
    return (
        <div className='main'>
         <div className='container'>
          <Title level={1}>Nossos serviços</Title>
          <div className='divider-s'></div>
        </div>
        <div className='text-container-s'>
        <p className='text'>
        Leve o Orion para a sua empresa e melhore os seus processos de captação de recursos, prestação de contas de projetos, inovação e diagnóstico tecnológico.
        </p></div>
  
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: '40px' }}>
          {services.map((service, index) => (
            <Col xs={24} sm={12} md={4} key={index}>
              <div className='service-icon'>
              {service.icon}
            </div>
            <Title level={4} style={{ marginTop: '20px', color: '#6fcf97' }}>
                  {service.title}
            </Title>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

