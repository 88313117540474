import React from 'react';
import './TechSection.css';
import lamp from "../../../assets/icons/lamp.svg";
import file from "../../../assets/icons/file-search-two.svg";
import analysis from "../../../assets/icons/analysis.svg";
import optimization from "../../../assets/icons/optimization.svg";
import datascience from "../../../assets/icons/data-science.svg";
import orientation from "../../../assets/icons/orientation.svg";


const techData = [
  {
    title: 'Inovação',
    description: 'Transformamos dados em conhecimento estratégico.',
    icon: <img src={lamp} alt="Lamp" style={{ width: '48px', height: '48px' }}  />,
  },
  {
    title: 'Pesquisa',
    description: 'Sua empresa explora novas fronteiras em Ciência de Dados.',
    icon: <img src={file} alt="file" style={{ width: '48px', height: '48px' }} />,
  },
  {
    title: 'Orientação',
    description: 'Transformamos alunos em profissionais e Profissionais em líderes.',
    icon: <img src={orientation} alt="orientation" style={{ width: '48px', height: '48px' }} />,
  },
  {
    title: 'Otimização',
    description: 'Coleta de dados à inferência de tomada de decisão, passando por saneamento e organização de grande volume de dados.',
    icon: <img src={optimization} alt="optimization" style={{ width: '48px', height: '48px' }} />,
  },
  {
    title: 'Ciência de Dados',
    description: 'Construção de modelos de dados personalizados às necessidades do seu negócio.',
    icon: <img src={datascience} alt="data-science" style={{ width: '48px', height: '48px' }} />,
  },
  {
    title: 'Análise',
    description: 'Construímos Pipelines de Dados e Visualizações que impulsionam empresas visionárias.',
    icon: <img src={analysis} alt="Lamp" style={{ width: '48px', height: '48px' }} />,
  },
];

export function TechSection() {
    return (
      <div className="tech-section">
        {techData.map((tech, index) => (
          <div key={index} className="tech-item">
            <div className="tech-icon">{tech.icon}</div>
            <div className="tech-info">
              <h3 className="tech-title">{tech.title}</h3>
              <p className="tech-description">{tech.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

